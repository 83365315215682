<template>
    <game-items-list game-item-variant="skins">
        <template #title>
            {{ $t('skins.list') }}
        </template>
    </game-items-list>
</template>

<script>
import GameItemsList from '@/views/game-items/game-items-list/GameItemsList.vue';

export default {
    components: {
        GameItemsList,
    },
};
</script>

<style lang="scss" scoped></style>
